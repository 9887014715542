<template>
	<v-navigation-drawer
			id="app-drawer"
			v-model="inputValue"
			app
			mobile-breakpoint="991"
			persistent
			width="260"
	>

		<v-list-item two-line>
			<v-list-item-avatar color="white">
				<v-img
					:src="require('@/assets/wdht-logo.svg')"
					height="34"
					contain
				/>
			</v-list-item-avatar>

			<v-list-item-title class="title body-1" >
				We Don't Have Time
			</v-list-item-title>
		</v-list-item>

		<v-list nav>
			<!-- Bug in Vuetify for first child of v-list not receiving proper border-radius -->
			<div />

			<template v-for="(link, i) in links">
				<v-list-group
						v-if="link.sublinks"
						no-action
						:key="i"
						:prepend-icon="link.icon"
				>
					<template v-slot:activator>
						<v-list-item-title v-text="link.text"></v-list-item-title>
					</template>

					<template v-for="(sublink, i) in link.sublinks">
						<v-list-item
							v-if="sublink.url"
							:key="i"
							:href="sublink.url"
							active-class="primary white--text"
						>
							<v-list-item-title v-text="sublink.text" />
						</v-list-item>
						<v-list-item
							v-else
							:key="i"
							:to="sublink.to"
							active-class="primary white--text"
						>
							<v-list-item-title v-text="sublink.text" />
						</v-list-item>
					</template>
				</v-list-group>

				<v-list-item
						v-else
						:key="i"
						:to="link.to"
						active-class="primary white--text"
				>
					<v-list-item-icon>
						<v-icon v-text="link.icon" />
					</v-list-item-icon>
					<v-list-item-title v-text="link.text" />
				</v-list-item>
			</template>

		</v-list>

		<v-list style="position:absolute;bottom:0;">
			<v-list-item>
				<span class="caption">
					v{{ version }}
				</span>
			</v-list-item>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	import { mapGetters } from 'vuex'

	export default {
		props: {
			opened: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
			links: [],
		}),
		name: `CoreDrawer`,
		computed: {
			...mapGetters([`getAdmin`]),
			inputValue: {
				get() {
					return this.$store.getters.getDrawer()
				},
				set(state) {
					this.$store.dispatch(`setDrawer`, state)
				}
			},
			version() {
				return process.env.VUE_APP_VERSION
			},
		},
		mounted() {
			this.$httpInt.get(`/v2/system/menu`)
				.then(res => this.links = res.data)
		},
	}
</script>
